<template>
	<div class="section features-section">
    <ul class="list">
      <li class="item">
        <div class="icon">
          <img src="./../assets/shield.svg" class="img"/>
        </div>
        <div class="title">{{ $t('100% безопасность') }}</div>
        <div class="desc">{{ $t('Все транзакции тщательно контролируются, поэтому ваши деньги и скины в безопасности.') }}</div>
      </li>
      <li class="item">
        <div class="icon">
          <img src="./../assets/layer.svg" class="img"/>
        </div>
        <div class="title">{{ $t('Широкий выбор') }}</div>
        <div class="desc">{{ $t('Существует множество вариаций уникальных и качественных скинов.') }}</div>
      </li>
      <li class="item">
        <div class="icon">
          <img src="./../assets/discount.svg" class="img"/>
        </div>
        <div class="title">{{ $t('Лучшие цены') }}</div>
        <div class="desc">{{ $t('CS — это не светошумовые гранаты ') }}</div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
	name: 'Features',
	props: [],
	data: function() {
		return {
		}
	},
	methods: {
		
	},
	mounted() {
    
  }
}
</script>
