<template>
  <div class="menu">
    <ul class="nav">
      <template v-for="(item, i) in $parent.nav">
        <li class="nav__item" :key="i" v-if="item.link != '/privacy' && item.link != '/terms'">
          <router-link :to="item.link" exact>
            <span v-if="item.title == 'New Arrivals'">{{ $t('New') }}</span>
            <template v-if="item.title == 'New Arrivals'">
              {{ $t('Arrivals') }}
            </template>
            <template v-else>
              {{ item.title != 'Sale' ? $t(item.title) : '' }}
            </template>
            <span class="red" v-if="item.title == 'Sale'">{{ $t('Sale') }}</span>
          </router-link>
        </li>
      </template>

      <div class="select-container" v-if="curr">
        <Select2 class="select2 single currency-select" v-model="curr"
                 @select="selectCurr($event)"
                 :placeholder="curr"
                 :options="$parent.currencyOptions"
        />
      </div>

      <div class="select-container language-select-container" v-if="currentLanguage">
        <Select2 class="select2 single currency-select" v-model="language"
                 @select="selectLanguage($event)"
                 :options="languages"
                 :placeholder="currentLanguageName"
        />
      </div>


      <!-- <div class="nav__item" v-if="$route.name == 'Profile'  || $route.name == 'SellOffers'">
      <a @click="$emit('openSaleSkinModal')">Sale your skin</a>
      </div> -->
    </ul>
  </div>
</template>

<script>
import Select2 from 'vue3-select2-component';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: 'Menu',
  props: ['currencyCode'],
  components: {
    Select2
  },
  data: function () {
    return {
      curr: ''
    }
  },
  computed: {
    ...mapState({
      languages: ({app: {languages}}) => languages.map(({id, title: text}) => ({id, text})),
      currentLanguage: state => state.app.currentLanguage,
    }),
    ...mapGetters('app', ['currentLanguageName']),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      }
    }
  },
  watch: {
    currencyCode(newValue) {
      this.curr = newValue
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
  methods: {
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
      this.$emit('closeMenu')
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    ...mapMutations('app' , ['setCurrentLanguage']),
    ...mapActions('app', ['changeLocale'])
  },
}
</script>
