<template>
  <main class="main csgo-products-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title spec">{{ $t('CS:GO Скины') }} {{ sort }}</div>
          <div class="products-content">
            <div :class="['products-content-left', {'active': filtersIsVisible}]">
              <div class="top">
                <div class="wrapper">
                  <div class="right">
                    <span class="desc">{{ $t('Поиск') }}</span>
                    <div class="search">
                      <img class="img" src="./../assets/magnifying-glass.svg" @click="filterProducts()"/>
                      <input type="text" v-model="search" v-on:keyup.enter="onEnter"/>
                    </div>
                  </div>
                  <div class="left">
                    <div :class="['filter-btn', {'active': filtersIsVisible}]" @click="toggleFilter">
                      <div class="filter-btn-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 19.774 14.125">
                          <path id="fillter" d="M-1978.526-1260.876a1.413,1.413,0,0,1-1.412-1.413,1.412,1.412,0,0,1,1.412-1.412h2.825a1.412,1.412,0,0,1,1.412,1.412,1.413,1.413,0,0,1-1.412,1.413Zm-3.531-5.65a1.413,1.413,0,0,1-1.413-1.413,1.413,1.413,0,0,1,1.413-1.412h9.887a1.412,1.412,0,0,1,1.412,1.412,1.413,1.413,0,0,1-1.412,1.413Zm-3.531-5.65a1.412,1.412,0,0,1-1.412-1.412,1.413,1.413,0,0,1,1.412-1.413h16.949a1.412,1.412,0,0,1,1.412,1.413,1.412,1.412,0,0,1-1.412,1.412Z" transform="translate(1987 1275)" fill="#fff"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="fade">
                <div class="list products" v-if="productList">
                  <div class="item" v-for="(item, i) in productList" :key="i">
                    <div class="item-wrapper">
                      <div class="price-container">
                        <div class="desc">{{item.category}}</div>
                        <div class="price title">
                          <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} {{$parent.currency}}</span>
                          <span>{{item.price}} {{$parent.currency}}</span>
                        </div>
                      </div>
                      <a class="link button" @click="$parent.addToCart(item, item.id)">
                        <span>{{ $t('В корзину') }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.945" height="20.957" viewBox="0 0 20.945 20.957">
                          <g id="shopping-cart" transform="translate(-0.134)">
                            <g id="Сгруппировать_29" data-name="Сгруппировать 29" transform="translate(0.134 0)">
                              <path id="Линия_1" data-name="Линия 1" d="M-19.183-15.469a.826.826,0,0,1-.111-.008.819.819,0,0,1-.7-.921l.394-2.9a.819.819,0,0,1,.921-.7.819.819,0,0,1,.7.921l-.394,2.9A.819.819,0,0,1-19.183-15.469Z" transform="translate(34.887 26.914)" fill="#282828"/>
                              <path id="Линия_2" data-name="Линия 2" d="M-18.4-15.471a.819.819,0,0,1-.789-.6l-.788-2.9a.819.819,0,0,1,.575-1,.819.819,0,0,1,1,.575l.788,2.9a.819.819,0,0,1-.575,1A.82.82,0,0,1-18.4-15.471Z" transform="translate(27.695 26.916)" fill="#282828"/>
                              <path id="Контур_222" data-name="Контур 222" d="M3.776,5.149a.819.819,0,0,1-.786-.593L2.226,1.9a.365.365,0,0,0-.349-.262H.953A.819.819,0,0,1,.953,0h.924A2.01,2.01,0,0,1,3.8,1.447L4.563,4.1a.819.819,0,0,1-.787,1.045Z" transform="translate(-0.134 0)" fill="#282828"/>
                              <path id="Контур_223" data-name="Контур 223" d="M146.713,327.368H136.657A2.747,2.747,0,0,1,134,325.306l-.418-1.623a.819.819,0,0,1,1.586-.408l.418,1.623a1.11,1.11,0,0,0,1.076.833h10.056a.819.819,0,0,1,0,1.637Z" transform="translate(-128.091 -309.453)" fill="#282828"/>
                              <path id="Эллипс_33" data-name="Эллипс 33" d="M-17.666-20a2.339,2.339,0,0,1,2.334,2.34,2.339,2.339,0,0,1-2.334,2.34A2.339,2.339,0,0,1-20-17.66,2.339,2.339,0,0,1-17.666-20Zm0,3.042a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7.7.7,0,0,0-.7.7A.7.7,0,0,0-17.666-16.958Z" transform="translate(26.644 36.277)" fill="#282828"/>
                              <path id="Эллипс_34" data-name="Эллипс 34" d="M-17.666-20a2.339,2.339,0,0,1,2.334,2.34,2.339,2.339,0,0,1-2.334,2.34A2.339,2.339,0,0,1-20-17.66,2.339,2.339,0,0,1-17.666-20Zm0,3.042a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7.7.7,0,0,0-.7.7A.7.7,0,0,0-17.666-16.958Z" transform="translate(34.22 36.277)" fill="#282828"/>
                              <path id="Линия_3" data-name="Линия 3" d="M-19.181-15.467A.819.819,0,0,1-20-16.285v-2.9A.819.819,0,0,1-19.181-20a.819.819,0,0,1,.819.819v2.9A.819.819,0,0,1-19.181-15.467Z" transform="translate(31.632 26.911)" fill="#282828"/>
                            </g>
                            <path id="Контур_224" data-name="Контур 224" d="M83.221,97.126H72.586a.819.819,0,0,1-.79-.6l-2.637-9.7a.819.819,0,0,1,.79-1.033H84.487a2.763,2.763,0,0,1,2.739,3.134l-.159,1.412a.819.819,0,0,1-1.627-.183l.161-1.43a1.126,1.126,0,0,0-1.114-1.295H71.019l2.192,8.058h10.01a1.677,1.677,0,0,0,1.656-1.444.819.819,0,0,1,1.623.212A3.322,3.322,0,0,1,83.221,97.126Z" transform="translate(-66.172 -82.281)" fill="#282828"/>
                          </g>
                        </svg>
                      </a>
                      <a @click="$parent.goToProduct(item)" class="preview">
                        <img :src="imgDomain + item.img_url" class="img"/>
                        <transition name="fade">
                          <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Добавлено') }}</span>
                        </transition>
                      </a>
                      <a @click="$parent.goToProduct(item)" class="title">{{item.title}}</a>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <transition name="slide">
              <div class="products-content-right" v-if="filtersIsVisible">
                <div class="filters">
                  <div class="filters-wrapper">
                    <img class="close" @click="toggleFilter()" src="./../assets/close.svg"/>
                    <div class="title">{{ $t('Фильтры') }}</div>
                    <div class="select-container" v-if="translatedSortOptions.length">
                      <div class="desc">{{ $t('Сортировать') }}:</div>
                      <Select2 class="select2 single" v-model="sort" 
                      :options="translatedSortOptions"
                      :placeholder="$t('От высокой цены')"
                      @select="selectSort($event)"
                      />
                    </div>
                    <div class="select-container" v-if="typeOptions.length">
                      <div class="desc">{{ $t('Тип') }}:</div>
                      <Select2 class="select2 single" v-model="category" 
                      :options="typeOptions" 
                      :placeholder="$t('Все типы')"
                      @select="selectCategory($event)"
                      />
                    </div>
                    <div class="select-container" v-if="qualityOptions.length">
                      <div class="desc">{{ $t('Качество') }}:</div>
                      <Select2 class="select2 single" v-model="quality" 
                      :options="qualityOptions" 
                      :placeholder="$t('Все качества')"
                      @select="selectQuality($event)"
                      />
                    </div>
                    <div class="select-container select-container-range">
                      <div class="desc">{{ $t('Цена') }}:</div>
                      <div class="input-container">
                        <div class="desc">{{ $t('От') }}</div>
                        <div class="desc">{{barMinValue}} {{$parent.currency}}</div>
                      </div>
                      <div class="input-container">
                        <div class="desc">{{ $t('До') }}</div>
                        <div class="desc">{{barMaxValue}} {{$parent.currency}}</div>
                      </div>
                      <MultiRangeSlider
                        :min="0"
                        :max="currencyCode == 'KZT' ? 750000 : 1500"
                        :step="10"
                        :ruler="false"
                        :label="false"
                        :rangeMargin="0"
                        :minValue="barMinValue"
                        :maxValue="barMaxValue"
                        @input="updateRangeValues"
                      />
                    </div>
                    <div class="select-container select-container-reset">
                      <div class="reset" @click="resetFilters">
                        <img src="./../assets/refreshing.svg" class="img"/>
                        <div class="desc">{{ $t('Сбросить все фильтры') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <pagination v-if="totalProducts" v-model="activePage" :records="totalProducts" :per-page="24" @paginate="changePage"/>
        </div>
      </section>
      <Features/>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';
import Pagination from 'vue-pagination-2';
import MultiRangeSlider from "multi-range-slider-vue";
import Features from '../components/Features.vue';

export default {
  name: 'CsGoProducts',
  props: ['currencyCode'],
  components: {
    Select2,
    Pagination,
    MultiRangeSlider,
    Features
  },
  data: function() {
    return {
      imgDomain: '',
      filtersIsVisible: false,
      search: '',
      sort: 'desc',
      category: '',
      quality: '',
      activePage: 1,
      totalProducts: null,
      sortOptions: [
        {
          id: 'desc',
          text: 'От высокой цены'
        }, 
        {
          id: 'asc',
          text:'От низкой цены'
        }
      ],
      categoryOptions: [],
      typeOptions: [],
      qualityOptions: [],
      barMinValue: 0,
      barMaxValue: 1500,
      dragged: false,
      productList: []
    }
  },
  computed: {
    translatedSortOptions() {
      return this.sortOptions.map((option) => ({text: this.$t(option.text), id: option.id}))
    }
  },
  watch: {
    currencyCode() {
      this.checkCurr();
      this.filterProducts();
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'items/list?page=1&sort=desc&limit=24&currency=' + this.$parent.currencyCode)
    .then((res) => {
      this.productList = res.data.payload
      this.totalProducts = res.data.meta.total
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })

    this.getCategoryOptions();
    this.getQualityOptions();
    this.getTypeOptions();
    
    addEventListener("mouseup", () => {
      if (this.dragged) {
        this.filterProducts();
        this.dragged = false;
      }
    });
    addEventListener("touchend", () => {
      if (this.dragged) {
        this.filterProducts();
        this.dragged = false;
      }
    });
    this.checkCurr();
  },
  methods: {
    checkCurr() {
      if (this.currencyCode == 'KZT') {
        this.barMaxValue = 750000
      } else {
        this.barMaxValue = 1500
      }
    },
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activePage = 1;
      this.category = event.text;
      this.filterProducts();
    },
    selectQuality(event) {
      this.activePage = 1;
      this.quality = event.text;
      this.filterProducts();
    },
    resetFilters() {
      let self = this;
      this.barMinValue = 0;
      setTimeout(() => {
        self.checkCurr();
      }, 100);
      
      this.quality = this.$t('Все качества');
      this.category = this.$t('Все типы');
      this.categoryOptions = '';
      this.qualityOptions = '';
      this.getCategoryOptions();
      this.getTypeOptions();
      this.getQualityOptions();
      this.sortOptions = [];
      setTimeout(() => {
        self.sortOptions = [
          {
            id: 'desc',
            text: 'От высокой цены'
          }, 
          {
            id: 'asc',
            text:'От низкой цены'
          }
        ]
      }, 100);
      
      this.selectSort(
        {
          text: "From high price",
          id: "desc"
        }
      )
      setTimeout(() => {
        self.filterProducts();
      }, 300);
    },
    toggleFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {

      let addCategory = '';
      if (this.category != this.$t('Все типы')) {
        addCategory = 'type=' + this.category
      } else {
        addCategory = '';
      }

      let addQuality = '';
      if (this.quality != this.$t('Все качества')) {
        addQuality = '&quality=' + this.quality
      } else {
        addQuality = '';
      }
      let addPrice = '&price_from=' + this.barMinValue + '&price_till=' + this.barMaxValue;

      let addSort = '&sort=' + this.sort

      let addPage = '&page=' + this.activePage
      
      let addSearch = '';
      if (this.search) {
        addSearch = '&search=' + this.search
      } else {
        addSearch = ''
      }

      let addCurrency = '';
      addCurrency = '&currency=' + this.$parent.currencyCode;
      this.$http.get(process.env.VUE_APP_API + 'items/list?' + addCategory + addQuality + addPrice + addSort + addPage + addSearch + '&limit=24' + addCurrency)
      .then((res) => {
        this.productList = res.data.payload
        this.totalProducts = res.data.meta.total
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        const newCategories = res.data.payload.map(({
          id: id,
          title: text,
          url: url
        }) => ({
          id,
          text,
          url
        }));
        newCategories.unshift({id: 0, text: this.$t('Все типы'), url: ''})
        this.categoryOptions = newCategories;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getQualityOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        let newQuality = res.data.payload.qualities;
        newQuality.unshift(this.$t('Все качества'));
        this.qualityOptions = newQuality;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getTypeOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        let newType = res.data.payload.types;
        newType.unshift(this.$t('Все типы'));
        this.typeOptions = newType;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    updateRangeValues(e) {
      this.activePage = 1;
      this.barMinValue = e.minValue;
      this.barMaxValue = e.maxValue;

      this.dragged = true;
    },
    onEnter() {
      this.activePage = 1;
      this.filterProducts();
    }
  }
}
</script>