<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="title spec">{{ $t('Корзина') }}</div>
        <div class="wrapper">
          <div class="left">
            <div class="product-list">
              <div v-if="$parent.cartContents && !$parent.cartContents.length" class="title">Cart is empty!</div>
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img"/>
                </div>
                <div class="title">{{item.item.title}}</div>
                <div class="count">
                  <div class="count__item minus" @click="$parent.removeOneFromCart(item, item.item_id)"></div>
                  <input type="text" value="1" v-model="item.count"/>
                  <div class="count__item plus" @click="$parent.addToCart(item.item, item.item_id)"></div>
                </div>
                <div class="price">{{item.item.price}} {{$parent.currency}}</div>
                <div class="close" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../assets/close.svg" class="img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="wrapper">
              <div class="title">{{ $t('Личная информация') }}</div>
              <div class="input-container">
                <div class="desc">{{ $t('Имя') }}:</div>
                <input type="text" v-model="name"/>
              </div>
              <div class="input-container">
                <div class="desc">{{ $t('Фамилия') }}:</div>
                <input type="text" v-model="surname"/>
              </div>
              <div class="input-container">
                <div class="desc">{{ $t('Телефон') }}:</div>
                <input type="tel" v-model="phone"/>
              </div>
              <div class="input-container steam">
                <div class="desc">*{{ $t('Steam ID') }}:</div>
                <input type="text" v-model="steamId"/>
              </div>
              <div class="input-container">
                <div class="desc">{{ $t('Страна') }}:</div>
                <Select2 class="select2 single" v-model="country" 
                :options="countryOptions" 
                @select="selectCountry($event)"
                :placeholder="$t('Страна')"
                 />
              </div>
              <div class="input-container">
                <div class="desc">{{ $t('Город') }}:</div>
                <input type="text" v-model="city"/>
              </div>
              <div class="input-container">
                <div class="desc">{{ $t('Адрес') }}:</div>
                <input type="text" v-model="address"/>
              </div>
              <div class="input-container">
                <div class="desc">{{ $t('Почтовый индекс') }}:</div>
                <input type="text" v-model="postCode"/>
              </div>
              <div class="input-container">
                <div class="desc"></div>
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="title">{{ $t('Я согласен с') }} </span>
                      <a @click="$parent.goToPrivacy()" class="title"> {{ $t('политикой конфиденциальности') }}</a>
                      <span class="title">  и </span>
                      <a @click="$parent.goToTerms()" class="title"> {{ $t('условиями') }}</a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="title">{{ $t('Платежный метод') }}</div>
              <div class="row">
                <div class="desc">{{ $t('Итого к оплате') }}:</div>
                <div class="title" v-if="$parent.totalSum">{{$parent.totalSum}} {{$parent.currency}}</div>
              </div>
              <button @click="submit" :class="['button bordered', {'disabled': !requiredFieldsAreFilled}]">
                <span>{{ $t('Credit / Debit cards') }}</span>
              </button>
              <transition name="fade">
                <div v-if="error" class="desc red">{{ error }}</div>
              </transition>
            </div>
          </div>
        </div>
      </section>
      <Features/>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';
import Features from '../components/Features.vue';

export default {
  name: 'Cart',
  props: [],
  components: {
    Select2,
    Features
  },
  data: function() {
    return {
      imgDomain: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      steamId: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      error: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCounties();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.steamId && this.country && 
        this.city && this.address  
        && this.postCode && this.terms && this.$parent.currencyCode != 'USD'
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
      console.log(this.countryId)
    },
    submit() {
      console.log(this.$parent.currencyCode)
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "steamId": this.steamId,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode,
        "currency": this.$parent.currencyCode,
        "paymentType": "card"
      }
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          console.log(res);
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function(item) {
            return item['title'];
          });
          let newCountriesId = arr.map(function(item) {
            return item['id'];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    }
  }
}
</script>