import axios from 'axios';
import store from "@/store";

const instance = axios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
    timeout: 5000, // set the default timeout for requests to 5 seconds
});

instance.interceptors.request.use(function (config) {
    const lang_id = store.getters['app/currentLanguage'];

    if (config.method === 'get') {
        config.params = {
            ...config.params,
            lang_id
        }

        // config.params['lang_id'] = lang_id;
    } else if(['post', 'put', 'patch', 'POST', 'PUT', 'PATCH'].includes(config.method)) {
        config.data = {
            ...config.data,
            lang_id
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default instance;