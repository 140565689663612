<template>
  <div class="modal sign-modal">
    <div class="overlay"  @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeSignInModal')" src="./../assets/close.svg"/>
      <div class="container">
        <div class="left">
          <img class="bg" src="./../assets/modal-bg.png"/>
          <transition name="fade">
            <div class="left-wrapper login-content" v-if="loginContentVisible">
              <div class="title">{{ $t('Ещё нет аккаунта?') }}</div>
              <div class="link switch-register" @click="switchToRegister()">{{ $t('Регистрация') }}</div>
            </div>
          </transition>
          <transition name="fade">
            <div class="left-wrapper register-content" v-if="registerContentVisible || forgotPassContentVisible">
              <div class="title">{{ $t('Уже есть аккаунт?') }}</div>
              <div class="link switch-login" @click="switchToLogin()">{{ $t('Войти') }}</div>
            </div>
          </transition>
        </div>
        <div class="form">
          <div class="form-wrapper">
            <div class="form-wrapper2">
              <transition name="fade">
                <div class="form-fields register-content" v-if="registerContentVisible">
                  <div class="form-fields-wrapper">
                    <div class="title small">{{ $t('Регистрация') }}</div>
                    <div class="label-list">
                      <label>
                        <div class="desc">{{ $t('Имя') }}:</div>
                        <input type="text" v-model="name"/>
                      </label>
                      <label>
                        <div class="desc">{{ $t('Фамилия') }}:</div>
                      <input type="text" v-model="surname"/>
                      </label>
                      <label>
                        <div class="desc">{{ $t('Э-почта') }}:</div>
                      <input type="email" v-model="email"/>
                      </label>
                      <label>
                        <div class="desc">{{ $t('Телефон') }}:</div>
                        <input type="tel" v-model="phone"/>
                      </label>
                      <label>
                        <div class="desc">{{ $t('Пароль') }}:</div>
                        <input type="password" v-model="pass"/>
                      </label>
                      <label>
                        <div class="desc">{{ $t('Подтвердите пароль') }}:</div>
                        <input type="password" v-model="passConfirm"/>
                      </label>
                    </div>
                    <div class="chekbox-container">
                      <label class="chekbox-label">
                        <div class="chekbox-label-wrapper">
                          <input type="checkbox" name="terms" v-model="terms"/>
                          <div class="checkbox"></div>
                          <span class="title">{{ $t('Я согласен с') }} </span>
                          <a @click="goToPrivacy()" class="title"> {{ $t('политикой конфиденциальности') }}</a>
                          <span class="title"> {{ $t('и') }} </span>
                          <a @click="goToTerms()" class="title"> {{ $t('условиями') }}</a>
                        </div>
                      </label>
                    </div>
                    <button :class="['button', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                      <span>{{ $t('Регистрация') }}</span>
                    </button>
                    <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
                  </div>
                </div>
              </transition>
              <transition name="fade">
                <div class="form-fields login-content" v-if="loginContentVisible">
                  <div class="form-fields-wrapper">
                    <div class="title small">{{ $t('Вход') }}</div>
                    <label>
                      <div class="desc">{{ $t('Э-почта') }}:</div>
                      <input type="email" v-model="email"/>
                    </label>
                    <label>
                      <div class="desc">{{ $t('Пароль') }}:</div>
                      <input type="password" v-model="pass"/>
                    </label>
                    <button class="button" @click="submitLogin">
                      <span>{{ $t('Вход') }}</span>
                    </button>
                    <div class="link" @click="switchToforgotPass">{{ $t('Забыли пароль?') }}</div>
                    <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
                  </div>
                </div>
              </transition>
              <transition name="fade">
                <div class="form-fields forgot-pass-content" v-if="forgotPassContentVisible">
                  <div class="form-fields-wrapper">
                    <div class="title small">{{ $t('Забыли пароль?') }}</div>
                    <label>
                      <div class="desc">{{ $t('Э-почта') }}:</div>
                      <input type="email" v-model="email"/>
                    </label>
                    <button class="button" @click="submitForgotPass">
                      <span>{{ $t('Восстановить') }}</span>
                    </button>
                    <transition name="fade">
                      <div v-if="$parent.successMessage" class="desc green">{{$parent.successMessage}}</div>
                    </transition>
                    <transition name="fade">
                      <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
                    </transition>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SignInModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      loginContentVisible: true,
      registerContentVisible: false,
      forgotPassContentVisible: false,
      profile: {},
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false
    }
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    closeSignInModal() {
      this.$emit('closeSignInModal')
    },
    switchToforgotPass() {
      this.loginContentVisible = false;
      this.$parent.clearError();
      setTimeout(()=> {
        this.forgotPassContentVisible = true;
      }, 500)
    },
    switchToRegister() {
      this.loginContentVisible = false;
      this.$parent.clearError();
      setTimeout(()=> {
        this.registerContentVisible = true;
      }, 500)
    },
    switchToLogin() {
      this.forgotPassContentVisible = false;
      this.registerContentVisible = false;
      this.$parent.clearError();
      setTimeout(()=> {
        this.loginContentVisible = true;
      }, 500)
    },
    submitLogin() {
      let loginData = {
        "username": this.email,
        "password": this.pass
      }
      this.$emit('login', loginData)
    },
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    },
    submitForgotPass() {
      let data = {
        "email": this.email
      }
      this.$emit('forgotPass', data)
    },
    goToPrivacy() {
      this.$emit('closeSignInModal');
      this.$parent.goToPrivacy();
    }
    ,
    goToTerms() {
      this.$emit('closeSignInModal');
      this.$parent.goToTerms();
    }
  }
}
</script>