<template>
  <main class="main csgo-products-page product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title spec">{{ $t('CS:GO Скины') }}</div>
          <router-link to="/csgo-products" class="back">
            <img src="./../assets/arrow-back.svg" class="img"/>
            <span class="desc">{{ $t('Назад к списку') }}</span>
          </router-link>
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="text">
                <div class="title">{{$parent.productPageItem.title}}</div>
                <div class="row">
                  <div class="desc">{{ $t('Тип') }}:</div>
                  <div class="desc">{{$parent.productPageItem.type}}</div>
                </div>
                <div class="row">
                  <div class="desc">{{ $t('Качество') }}:</div>
                  <div class="desc">{{$parent.productPageItem.quality}}</div>
                </div>
                <div class="row row-price" v-if="currency">
                  <div class="desc">{{ $t('Цена') }}:</div>
                  <div class="desc">{{$parent.productPageItem.price}} {{currency}}</div>
                </div>
                <button class="button" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                  <span>{{ $t('В корзину') }}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.945" height="20.957" viewBox="0 0 20.945 20.957">
                    <g id="shopping-cart" transform="translate(-0.134)">
                      <g id="Сгруппировать_29" data-name="Сгруппировать 29" transform="translate(0.134 0)">
                        <path id="Линия_1" data-name="Линия 1" d="M-19.183-15.469a.826.826,0,0,1-.111-.008.819.819,0,0,1-.7-.921l.394-2.9a.819.819,0,0,1,.921-.7.819.819,0,0,1,.7.921l-.394,2.9A.819.819,0,0,1-19.183-15.469Z" transform="translate(34.887 26.914)" fill="#282828"/>
                        <path id="Линия_2" data-name="Линия 2" d="M-18.4-15.471a.819.819,0,0,1-.789-.6l-.788-2.9a.819.819,0,0,1,.575-1,.819.819,0,0,1,1,.575l.788,2.9a.819.819,0,0,1-.575,1A.82.82,0,0,1-18.4-15.471Z" transform="translate(27.695 26.916)" fill="#282828"/>
                        <path id="Контур_222" data-name="Контур 222" d="M3.776,5.149a.819.819,0,0,1-.786-.593L2.226,1.9a.365.365,0,0,0-.349-.262H.953A.819.819,0,0,1,.953,0h.924A2.01,2.01,0,0,1,3.8,1.447L4.563,4.1a.819.819,0,0,1-.787,1.045Z" transform="translate(-0.134 0)" fill="#282828"/>
                        <path id="Контур_223" data-name="Контур 223" d="M146.713,327.368H136.657A2.747,2.747,0,0,1,134,325.306l-.418-1.623a.819.819,0,0,1,1.586-.408l.418,1.623a1.11,1.11,0,0,0,1.076.833h10.056a.819.819,0,0,1,0,1.637Z" transform="translate(-128.091 -309.453)" fill="#282828"/>
                        <path id="Эллипс_33" data-name="Эллипс 33" d="M-17.666-20a2.339,2.339,0,0,1,2.334,2.34,2.339,2.339,0,0,1-2.334,2.34A2.339,2.339,0,0,1-20-17.66,2.339,2.339,0,0,1-17.666-20Zm0,3.042a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7.7.7,0,0,0-.7.7A.7.7,0,0,0-17.666-16.958Z" transform="translate(26.644 36.277)" fill="#282828"/>
                        <path id="Эллипс_34" data-name="Эллипс 34" d="M-17.666-20a2.339,2.339,0,0,1,2.334,2.34,2.339,2.339,0,0,1-2.334,2.34A2.339,2.339,0,0,1-20-17.66,2.339,2.339,0,0,1-17.666-20Zm0,3.042a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7.7.7,0,0,0-.7.7A.7.7,0,0,0-17.666-16.958Z" transform="translate(34.22 36.277)" fill="#282828"/>
                        <path id="Линия_3" data-name="Линия 3" d="M-19.181-15.467A.819.819,0,0,1-20-16.285v-2.9A.819.819,0,0,1-19.181-20a.819.819,0,0,1,.819.819v2.9A.819.819,0,0,1-19.181-15.467Z" transform="translate(31.632 26.911)" fill="#282828"/>
                      </g>
                      <path id="Контур_224" data-name="Контур 224" d="M83.221,97.126H72.586a.819.819,0,0,1-.79-.6l-2.637-9.7a.819.819,0,0,1,.79-1.033H84.487a2.763,2.763,0,0,1,2.739,3.134l-.159,1.412a.819.819,0,0,1-1.627-.183l.161-1.43a1.126,1.126,0,0,0-1.114-1.295H71.019l2.192,8.058h10.01a1.677,1.677,0,0,0,1.656-1.444.819.819,0,0,1,1.623.212A3.322,3.322,0,0,1,83.221,97.126Z" transform="translate(-66.172 -82.281)" fill="#282828"/>
                    </g>
                  </svg>
                </button>
              </div>
              <div class="preview-container">
                <div class="preview">
                  <transition name="fade">
                    <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">{{ $t('Добавлено') }}</span>
                  </transition>
                  <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <div class="section arrivals-section recommended-section">
        <div class="top">
          <div class="title spec">{{ $t('Рекомендуемые') }}</div>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.similarProducts.length" :list="$parent.similarProducts" :tag="$t('Рекомендуемые')" />
          <router-link to="/new-arrivals" class="card-link">{{ $t('Смотреть все') }}</router-link>
        </div>
      </div>
      <Features/>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue'
import Features from '../components/Features.vue'
export default {
  name: 'ProductPage',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    SlickItem,
    Features
  },
  data: function() {
    return {
      
    }
  },
  created() {
  },
  mounted() {
    
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    }
  }
}
</script>