<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title spec">{{ $t('Личный кабинет')  }}</div>
          <div class="profile-form">
            <div class="profile-form-wrapper">
              <div class="wrapper">
                <div class="title">{{ $t('Личная информация') }}</div>
                <div>
                  <div class="input-container">
                    <div class="desc">{{ $t('Имя') }}:</div>
                    <input type="text" v-model="name"/>
                  </div>
                  <div class="input-container">
                    <div class="desc">{{ $t('Фамилия') }}:</div>
                    <input type="text" v-model="surname"/>
                  </div>
                  <div class="input-container">
                    <div class="desc">{{ $t('Э-почта') }}:</div>
                    <input type="email" v-model="email"/>
                  </div>
                  <div class="input-container">
                    <div class="desc">{{ $t('Телефон') }}:</div>
                    <input type="tel" v-model="phone"/>
                  </div>
                </div>
                <button class="button bordered" @click="updateProfileData">
                  <span>{{ $t('Сохранить' ) }}</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="profileUpdateErrorMessaage">{{profileUpdateErrorMessaage}}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="profileUpdateSuccessMessaage">{{profileUpdateSuccessMessaage}}</div>
                </transition>
              </div>
              <div class="wrapper">
                <div class="title">{{ $t('Изменения пароля') }}:</div>
                <div class="input-container">
                  <div class="desc">{{ $t('Текущий пароль') }}:</div>
                  <input type="text" v-model="curPass"/>
                </div>
                <div class="input-container">
                  <div class="desc">{{ $t('Новый пароль') }}:</div>
                  <input type="text" v-model="newPass"/>
                </div>
                <div class="input-container">
                  <div class="desc">{{ $t('Подтвердить новый пароль') }}:</div>
                  <input type="text" v-model="repeatPass"/>
                </div>
                <button class="button bordered" @click="updatePassword">
                  <span>Сохранить</span>
                </button>
                <transition name="fade">
                  <div class="desc red" v-if="passUpdateErrorMessaage">{{passUpdateErrorMessaage}}</div>
                </transition>
                <transition name="fade">
                  <div class="desc green" v-if="passUpdateSuccessMessaage">{{passUpdateSuccessMessaage}}</div>
                </transition>
              </div>
            </div>
          </div>
          <div class="profile-table" v-if="$parent.orderHistory">
            <div class="profile-table-wrapper profile-form-wrapper">
              <div class="title">{{ $t('Мои заказы') }}</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>{{ $t('ID') }}</td>
                      <td>{{ $t('Дата/Время') }}</td>
                      <td>{{ $t('Название скина') }}</td>
                      <td>{{ $t('Статус') }}</td>
                      <td>{{ $t('Итого') }}</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in $parent.orderHistory" :key="i">
                      <td>{{item.id}}</td>
                      <td>{{$parent.formatDate(item.created_at)}}</td>
                      <td>{{item.title}}</td>
                      <td :class="[{'yellow': item.status == $t('Успешно')}]">{{item.status}}</td>
                      <td>{{item.sum}} {{$parent.currency}}</td>
                      <td>
                        <a class="offer-modal-open" @click="$emit('openOfferDetailsModal', item)">{{ $t('Детали') }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination" id="pagination"></div>
            </div>
          </div>
        </div>
      </div>
      <Features/>
    </div>
  </main>
</template>
<script>
import Features from '../components/Features.vue'
export default {
  name: 'Profile',
  props: [],
  components: {
    Features
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      phone: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: ''
    }
  },
  mounted() {
    this.$http.get(process.env.VUE_APP_API + 'user/profile')
    .then((res) => {
        this.email = res.data.email;
        this.name = res.data.name;
        this.surname = res.data.surname;
        this.phone = res.data.phone;
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })
  },
  methods: {
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "phone": this.phone,
        "surname": this.surname
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          this.$parent.getProfileName();
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
  }
}
</script>