<template>
  <footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <a href="index.html" class="logo">
            <img src="./../assets/logo-mini.svg" class="img"/>
          </a>
          <div class="text">
            <div class="desc">{{ $t('Товарищество с ограниченной ') }}</div>
            <div class="desc" v-html="$t('Владелец карты сам несет ответственность')">
            </div>
          </div>
        </div>
        <div class="center">
          <ul class="list">
            <li class="item">
              <div class="title">{{ $t('Поддержка') }}:</div>
              <div class="desc">support@{{ $parent.hostname }}</div>
            </li>
            <li class="item">
              <div class="title">{{ $t('Маркетинг') }}:</div>
              <div class="desc">partners@{{ $parent.hostname }}</div>
            </li>
            <li class="item">
              <div class="title">{{ $t('Телефон') }}:</div>
              <div class="desc">87057676127</div>
            </li>
          </ul>
        </div>
        <div class="right">
          <ul class="nav">
            <template v-for="(item, i) in $parent.nav">
              <li class="nav__item" :key="i" v-if="item.title != 'Home'">
                <router-link :to="item.link" exact>
                  {{ $t(item.title) }}
                </router-link>
              </li>
            </template>
            <template v-for="item in $parent.textPageList">
              <li class="nav__item" :key="item.id">
                <a @click="$parent.goToPage(item)">
                  {{ $t(item.title) }}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <ul class="list">
          <li class="item">
            <img src="./../assets/mc_symbol.svg" class="img"/>
          </li>
          <li class="item">
            <img src="./../assets/visa.svg" class="img"/>
          </li>
          <li class="item">
            <img src="./../assets/3ds.png" class="img"/>
          </li>
        </ul>
        <div class="desc"> {{ new Date().getFullYear() }} {{ $t('2023 "СкинТек" - Обнови свой стиль игры') }}</div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  props: [],
  data: function () {
    return {}
  },
  methods: {},
  mounted() {

  }
}
</script>
