<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <router-link to="/" class="logo">
          <img src="./../assets/logo.svg" class="img"/>
        </router-link>
        <div class="select-container" v-if="curr">
          <Select2 class="select2 single currency-select" v-model="curr"
                   @select="selectCurr($event)"
                   :placeholder="curr"
                   :options="$parent.currencyOptions"
          />
        </div>

        <div class="select-container language-select-container" v-if="currentLanguage">
          <Select2 class="select2 single currency-select" v-model="language"
                   @select="selectLanguage($event)"
                   :options="languages"
                   :placeholder="currentLanguageName"
          />
        </div>

      </div>
      <ul class="nav">
        <template v-for="(item, i) in $parent.nav">
          <li class="nav__item" :key="i">
            <router-link :to="item.link" exact>{{ $t(item.title) }}</router-link>
          </li>
        </template>
      </ul>
      <div class="right">
        <router-link to="/cart" v-if="$parent.isAuth" class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.945" height="20.957" viewBox="0 0 20.945 20.957">
            <g id="shopping-cart" transform="translate(-0.134)">
              <g id="Сгруппировать_29" data-name="Сгруппировать 29" transform="translate(0.134 0)">
                <path id="Линия_1" data-name="Линия 1"
                      d="M-19.183-15.469a.826.826,0,0,1-.111-.008.819.819,0,0,1-.7-.921l.394-2.9a.819.819,0,0,1,.921-.7.819.819,0,0,1,.7.921l-.394,2.9A.819.819,0,0,1-19.183-15.469Z"
                      transform="translate(34.887 26.914)" fill="#fff"/>
                <path id="Линия_2" data-name="Линия 2"
                      d="M-18.4-15.471a.819.819,0,0,1-.789-.6l-.788-2.9a.819.819,0,0,1,.575-1,.819.819,0,0,1,1,.575l.788,2.9a.819.819,0,0,1-.575,1A.82.82,0,0,1-18.4-15.471Z"
                      transform="translate(27.695 26.916)" fill="#fff"/>
                <path id="Контур_222" data-name="Контур 222"
                      d="M3.776,5.149a.819.819,0,0,1-.786-.593L2.226,1.9a.365.365,0,0,0-.349-.262H.953A.819.819,0,0,1,.953,0h.924A2.01,2.01,0,0,1,3.8,1.447L4.563,4.1a.819.819,0,0,1-.787,1.045Z"
                      transform="translate(-0.134 0)" fill="#fff"/>
                <path id="Контур_223" data-name="Контур 223"
                      d="M146.713,327.368H136.657A2.747,2.747,0,0,1,134,325.306l-.418-1.623a.819.819,0,0,1,1.586-.408l.418,1.623a1.11,1.11,0,0,0,1.076.833h10.056a.819.819,0,0,1,0,1.637Z"
                      transform="translate(-128.091 -309.453)" fill="#fff"/>
                <path id="Эллипс_33" data-name="Эллипс 33"
                      d="M-17.666-20a2.339,2.339,0,0,1,2.334,2.34,2.339,2.339,0,0,1-2.334,2.34A2.339,2.339,0,0,1-20-17.66,2.339,2.339,0,0,1-17.666-20Zm0,3.042a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7.7.7,0,0,0-.7.7A.7.7,0,0,0-17.666-16.958Z"
                      transform="translate(26.644 36.277)" fill="#fff"/>
                <path id="Эллипс_34" data-name="Эллипс 34"
                      d="M-17.666-20a2.339,2.339,0,0,1,2.334,2.34,2.339,2.339,0,0,1-2.334,2.34A2.339,2.339,0,0,1-20-17.66,2.339,2.339,0,0,1-17.666-20Zm0,3.042a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7.7.7,0,0,0-.7.7A.7.7,0,0,0-17.666-16.958Z"
                      transform="translate(34.22 36.277)" fill="#fff"/>
                <path id="Линия_3" data-name="Линия 3"
                      d="M-19.181-15.467A.819.819,0,0,1-20-16.285v-2.9A.819.819,0,0,1-19.181-20a.819.819,0,0,1,.819.819v2.9A.819.819,0,0,1-19.181-15.467Z"
                      transform="translate(31.632 26.911)" fill="#fff"/>
              </g>
              <path id="Контур_224" data-name="Контур 224"
                    d="M83.221,97.126H72.586a.819.819,0,0,1-.79-.6l-2.637-9.7a.819.819,0,0,1,.79-1.033H84.487a2.763,2.763,0,0,1,2.739,3.134l-.159,1.412a.819.819,0,0,1-1.627-.183l.161-1.43a1.126,1.126,0,0,0-1.114-1.295H71.019l2.192,8.058h10.01a1.677,1.677,0,0,0,1.656-1.444.819.819,0,0,1,1.623.212A3.322,3.322,0,0,1,83.221,97.126Z"
                    transform="translate(-66.172 -82.281)" fill="#fff"/>
            </g>
          </svg>
          <div class="indicator">{{ cartContents.length }}</div>
        </router-link>
        <div class="link-container dropdown-open" v-if="$parent.isAuth">
          <div class="icon-container">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 22.5 22.5">
                <g id="g360" transform="translate(0.25 683.916)">
                  <path id="Контур_183" data-name="Контур 183"
                        d="M2012.617-4302.813a.877.877,0,0,1-.152-.113,11.372,11.372,0,0,1-3.245-3.878,11.1,11.1,0,0,1-1.219-5.09,11.263,11.263,0,0,1,11.25-11.25,11.263,11.263,0,0,1,11.25,11.25,11.113,11.113,0,0,1-1.218,5.09,11.391,11.391,0,0,1-3.245,3.878.952.952,0,0,1-.154.114,11.155,11.155,0,0,1-6.632,2.168A11.152,11.152,0,0,1,2012.617-4302.813Zm1.423-1.187a9.4,9.4,0,0,0,5.21,1.568,9.391,9.391,0,0,0,5.21-1.568,5.3,5.3,0,0,0-5.21-4.238A5.3,5.3,0,0,0,2014.04-4304Zm10.247-9.275a5.019,5.019,0,0,1-1.927,3.96,7.16,7.16,0,0,1,2.191,1.651,7.137,7.137,0,0,1,1.42,2.422,9.5,9.5,0,0,0,2.74-6.652,9.471,9.471,0,0,0-9.461-9.46,9.47,9.47,0,0,0-9.461,9.46,9.491,9.491,0,0,0,2.74,6.652,7.135,7.135,0,0,1,1.42-2.422,7.16,7.16,0,0,1,2.191-1.651,5.021,5.021,0,0,1-1.926-3.96,5.041,5.041,0,0,1,5.036-5.035A5.042,5.042,0,0,1,2024.287-4313.275Zm-8.285,0a3.252,3.252,0,0,0,3.247,3.25,3.252,3.252,0,0,0,3.247-3.25,3.25,3.25,0,0,0-3.247-3.246A3.25,3.25,0,0,0,2016-4313.275Z"
                        transform="translate(-2008.25 3639.229)" fill="#fff"/>
                </g>
              </svg>
            </div>
            <div class="desc">{{ $parent.profileName }}</div>
          </div>
          <div class="dropdown">
            <div class="nav__item">
              <router-link to="/profile">
                {{ $t('Личный кабинет') }}
              </router-link>
            </div>
            <!-- <div class="nav__item">
              <router-link to="sell-offers">
                Sell Offers
              </router-link>
            </div> -->
            <div class="nav__item nav__item-logout">
              <a @click="logout">
                <span>{{ $t('Выйти') }}</span>
                <img src="./../assets/log-out.svg" class="img"/>
              </a>
            </div>
          </div>
        </div>
        <button class="button bordered modal-open" id="signInBtn" @click="$emit('openSignInModal')" v-else>
          {{ $t('Войти') }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>

import Select2 from 'vue3-select2-component';
import {mapState, mapMutations, mapGetters, mapActions} from "vuex";

export default {
  name: 'Header',
  props: ['cartContents', 'currencyCode'],
  components: {
    Select2
  },
  data: function () {
    return {
      curr: ''
    }
  },
  computed: {
    ...mapState({
      languages: ({app: {languages}}) => languages.map(({id, title: text}) => ({id, text})),
      currentLanguage: state => state.app.currentLanguage,
    }),
    ...mapGetters('app', ['currentLanguageName']),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      }
    }
  },
  watch: {
    currencyCode(newValue) {
      this.curr = newValue
    },
    currentLanguage() {
      if (this.$route.name === 'TextPage') {
        this.$router.push({path: '/'})
      }
    }
  },
  mounted() {
    this.curr = this.currencyCode
  },
  methods: {
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    ...mapMutations('app' , ['setCurrentLanguage']),
    ...mapActions('app', ['changeLocale'])
  },
}
</script>
