<template>
  <main class="main profile-page sell-offers-page page-inside">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">{{ $t('Sell Offers') }}</div>
          <div class="profile-table" v-if="$parent.orderHistory">
            <div class="profile-table-wrapper profile-form-wrapper">
              <div class="title">{{ $t('Your Orders') }}</div>
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <td>{{ $t('ID') }}</td>
                      <td>{{ $t('Date/time') }}</td>
                      <td>{{ $t('Skin name') }}</td>
                      <td>{{ $t('Total') }}</td>
                      <td>{{ $t('Status') }}</td>
                      <td>{{ $t('Details') }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in $parent.orderHistory" :key="i">
                      <td>{{item.item_id}}</td>
                      <td>{{$parent.formatDate(item.created_at)}}</td>
                      <td>{{item.title}}</td>
                      <td>{{item.sum}} {{$parent.currency}}</td>
                      <td>Offer declined</td>
                      <td>
                        <a class="offer-modal-open" @click="$emit('openOfferDetailsModal', item)">View</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination" id="pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'SellOffers',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    openOfferDetailsModal() {
      this.$emit('openOfferDetailsModal')
    }
  }
}
</script>