<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="left">
<!--            <div class="title big"  ><span class="yellow">Добро пожаловать в</span> Skins<span class="yellow">King</span></div>-->
            <div class="title big" v-html="$t('Добро пожаловать в SkinsKing')"></div>

            <div class="desc">
              {{ $t('Просмотрите все скины') }}
            </div>
            <router-link to="csgo-products" class="button">
              <span>{{ $t('Перейти в магазин') }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19.122" height="15.797" viewBox="0 0 19.122 15.797">
                <path id="arrow" d="M15.148,6.25A1.247,1.247,0,0,1,16.4,7.5V21.114l4.522-4.522a1.247,1.247,0,0,1,1.764,1.764L16.03,25.007a1.247,1.247,0,0,1-2.129-.882V7.5A1.247,1.247,0,0,1,15.148,6.25ZM7.615,16.592a1.247,1.247,0,0,1,1.764,0L12.7,19.918a1.247,1.247,0,1,1-1.764,1.764L7.615,18.356a1.247,1.247,0,0,1,0-1.764Z" transform="translate(-6.25 23.047) rotate(-90)" fill="#282828" fill-rule="evenodd"/>
              </svg>
            </router-link>
          </div>
          <div class="right">
            <div class="img-container">
              <img src="./../assets/guns.png" class="img"/>
              <div class="item item-1">
                <div class="icon">
                  <img src="./../assets/shield.svg" class="img"/>
                </div>
                <div class="title">{{ $t('100% безопасность') }}</div>
              </div>
              <div class="item item-2">
                <div class="icon">
                  <img src="./../assets/layer.svg" class="img"/>
                </div>
                <div class="title">{{ $t('Широкий выбор') }}</div>
              </div>
              <div class="item item-3">
                <div class="icon">
                  <img src="./../assets/discount.svg" class="img"/>
                </div>
                <div class="title">{{ $t('Лучшие цены') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section arrivals-section">
        <div class="top">
          <div class="title spec">{{ $t('Новое поступление') }}</div>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.newArrivals.length" :list="$parent.newArrivals" :tag="$t('Новинка')" />
          <router-link to="/new-arrivals" class="card-link">{{ $t('Смотреть все') }}</router-link>
        </div>
      </div>
      <div class="section daily-offer-section">
        <div class="wrapper" @click="$parent.goToProduct(dailyOffer)">
          <div class="preview" v-if="dailyOffer.img_url">
            <img :src="imgDomain + dailyOffer.img_url" class="img"/>
          </div>
          <div class="right">
            <div class="title small">{{dailyOffer.title}}</div>
            <div class="title price">
              {{dailyOffer.price}}
              <span class="currency">{{currency}}</span>
            </div>
          </div>
          <div class="center">
            <div class="title">{{ $t('Ежедневное предложение') }}</div>
            <div class="countdown">
              <div class="column">
                <div class="num">6</div>
                <div class="desc">Days</div>
              </div>
              <div class="column">
                <div class="num">5</div>
                <div class="desc">Hours</div>
              </div>
              <div class="column">
                <div class="num">39</div>
                <div class="desc">Minutes</div>
              </div>
              <div class="column">
                <div class="num">15</div>
                <div class="desc">Seconds</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section arrivals-section sale-section">
        <div class="top">
          <div class="title spec">{{ $t('Распродажа') }}</div>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.saleProducts.length" :list="$parent.saleProducts" :tag="$t('Распродажа')" />
          <router-link to="/sale" class="card-link">{{ $t('Смотреть все') }}</router-link>
        </div>
      </div>
      <div class="section more-offers-section">
        <div class="wrapper">
          <div class="left img-container">
            <img class="img" src="./../assets/more-offers-img.png"/>
          </div>
          <div class="left">
            <div class="title big" v-html="$t('Более 1 тыс. предложений')"></div>
            <ul class="list">
              <li class="item desc">
                <div class="num">
                  <span>1</span>
                </div>
                <span>{{ $t('Множество вариантов скинов на выбор') }}</span>
              </li>
              <li class="item desc">
                <div class="num">
                  <span>2</span>
                </div>
                <span>{{ $t('Дешевые скины CS:GO по скидочным ценам') }}</span>
              </li>
              <li class="item desc">
                <div class="num">
                  <span>3</span>
                </div>
                <span>{{ $t('Это ваш шанс получить эпические предметы!') }}</span>
              </li>
            </ul>
            <router-link to="csgo-products" class="button">
              <span>{{ $t('Все предложения') }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19.122" height="15.797" viewBox="0 0 19.122 15.797">
                <path id="arrow" d="M15.148,6.25A1.247,1.247,0,0,1,16.4,7.5V21.114l4.522-4.522a1.247,1.247,0,0,1,1.764,1.764L16.03,25.007a1.247,1.247,0,0,1-2.129-.882V7.5A1.247,1.247,0,0,1,15.148,6.25ZM7.615,16.592a1.247,1.247,0,0,1,1.764,0L12.7,19.918a1.247,1.247,0,1,1-1.764,1.764L7.615,18.356a1.247,1.247,0,0,1,0-1.764Z" transform="translate(-6.25 23.047) rotate(-90)" fill="#282828" fill-rule="evenodd"/>
              </svg>
            </router-link>
          </div>
        </div>
      </div>
      <div class="section arrivals-section recommended-section">
        <div class="top">
          <div class="title spec">{{ $t('Рекомендуемые') }}</div>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.recommendedProducts.length" :list="$parent.recommendedProducts" :tag="$t('Рекомендуемые')" />
          <router-link to="/recommended" class="card-link">{{ $t('Смотреть все') }}</router-link>
        </div>
      </div>
      <Features/>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';
import Features from '../components/Features.vue'
export default {
  name: 'Home',
  props: ['addToCartChosenItem', 'currency', 'currencyCode'],
  components: {
    SlickItem,
    Features
  },
  data: function() {
    return {
      imgDomain: '',
      dailyOffer: {},
      sliderIsClickble: true
    }
  },
  watch: {
    currencyCode() {
      this.getDailyOffer();
    },
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getDailyOffer();
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    getDailyOffer() {
      this.$http.get(process.env.VUE_APP_API + 'items/daily?currency=' + this.$parent.currencyCode)
      .then((res) => {
        this.dailyOffer = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    }
  }
}
</script>